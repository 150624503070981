import React from "react";
import './SearchBar.css';
import { FaMicrophone, FaSearch, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";

function SearchBar() {
    const navigate = useNavigate();
    const getScreen = () => {
        return document.location.href.split('/').slice(-1)[0];
    }
    const searchRestaurants = (event) => {
        if (event.key == "Enter") {
            navigate('/search')
        }
    }

    return (
        <div className="search-container">
            <button className="left-icon" onClick={() => {
                let currentScreen = getScreen()
                if (currentScreen === "search" || currentScreen === "orders" || currentScreen === "restaurant") {
                    navigate('/')
                }
            }}>
                {getScreen() === "" ?
                    <FaMicrophone /> : <FaArrowLeft />
                }
            </button>
            <div className="search-bar" onKeyDown={searchRestaurants}>
                <input
                    type="text"
                    placeholder="Search restaurants..."
                />
                <button className="search-icon">
                    <FaSearch />
                </button>
            </div>
        </div>
    )

}

export default SearchBar;