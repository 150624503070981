import React from "react";
import { FaMicrophone, FaSearch, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import './RestaurantPage.css';
import MenuApp from '@cado/self-serve-tablet/src/App';

function RestaurantPage() {
    const navigate = useNavigate();
    const getScreen = () => {
        return document.location.href.split('/').slice(-1)[0];
    }

    return (
    	<div className="restaurant-page">
	        <MenuApp useCase="user-facing-app" />
        </div>
    )

}

export default RestaurantPage;