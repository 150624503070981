import logo from './logo.svg';
import './App.css';
import NavBarBottom from './NavBarBottom';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import HomePage from './HomePage';
import SearchPage from './SearchPage';
import RestaurantPage from './RestaurantPage';
import SearchBar from './SearchBar';
import OrdersPage from './OrderPage';


function App() {

  const getScreen = () => {
    let screen = document.location.href.split('/').slice(-1)[0];
    screen = screen === "" ? "search" : screen;
    return screen;
  }

  return (
    <Router>
      <div className="App">
          <SearchBar/>
          <Routes>
            <Route exact path="/" element={<HomePage/>} screen={getScreen()}></Route>
            <Route exact path="/search" element={<SearchPage/>} screen={getScreen()}></Route>
            <Route exact path="/orders" element={<OrdersPage/>} screen={getScreen()}></Route>
            <Route exact path="/restaurant" element={<RestaurantPage/>} screen={getScreen()}></Route>
          </Routes>
        <NavBarBottom />
      </div>
    </Router>
  );

}

export default App;
