import React from "react";
import './SearchPage.css';
import "./App.css";
import RestaurantList from "./RestaurantList";



function SearchPage(){

    return(
        <RestaurantList/>
    )

}
export default SearchPage;