import React from "react";
import OrderPreviewContainer from "./OrderPreview";

function OrdersPage(){

    return(
        <OrderPreviewContainer numOrdersToDisplay={Infinity} />
    )

}
export default OrdersPage;
