import "./RestaurantList.css";
import RestaurantCard from './RestaurantCard';
import React, { useState, useEffect } from 'react';
import { APIURL } from './constants';
function RestaurantList() {
    const [restaurantsData, setRestaurantsData] = useState(null);

    const load = async () => {
        let url = new URL(APIURL + '/get_restaurants_basic_info');
        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
        const restaurantsDataTmp = await fetch(url, options).then((res) => res.json());
        setRestaurantsData(restaurantsDataTmp)
    };

    useEffect(() => {
        load();
    }, []);

    let restaurantCards = null;
    if (restaurantsData !== null && restaurantsData["basicInfo"] !== null) {
        console.log("restaurantsData: " + JSON.stringify(restaurantsData))
        restaurantCards = restaurantsData["basicInfo"].map((restaurantCardData) => {
            return <RestaurantCard restaurantCardData={restaurantCardData} />
        });
    }
    return (
            <div className="restaurant-cards">
                {restaurantCards}
            </div>
    )
}

export default RestaurantList;