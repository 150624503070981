import "./HomePage.css";
import "./App.css";
import RestaurantCard from './RestaurantCard';
import OrderPreviewContainer from "./OrderPreview";
import React, { useState, useEffect } from 'react';
import { APIURL } from './constants';
import RestaurantList from "./RestaurantList";

function HomePage() {
    return (
        <>
            <OrderPreviewContainer numOrdersToDisplay={2} screen="HomePage" />
            <RestaurantList/>
        </>
    )
}

export default HomePage;