import React from "react";
import "./RestaurantCard.css";
import { useNavigate } from "react-router-dom";

function RestaurantCard({ restaurantCardData }){
    const navigate = useNavigate();
    function navigateToRestaurantPage(){
        navigate("/restaurant")
    }
    return(
        <div className="card-container" onClick={ navigateToRestaurantPage }>
            <Title restaurantTitle={restaurantCardData['restaurantTitle']} />
            <Subtitle status={restaurantCardData['status']} distanceAway={restaurantCardData['distanceAway']} foodType={restaurantCardData['foodType']} reviewInfo={restaurantCardData['reviewInfo']} />
            <Image imageUrl={restaurantCardData['imageUrl']} />
        </div>
    )
}

function Image({ imageUrl }){
    return(
        <div className="restaurant-image-container-user-facing-app">
            {/*<div className="map-pin">1</div>*/}
            <img className="restaurant-image-user-facing-app" src={imageUrl === "" ? "https://cado-assets.s3.amazonaws.com/logo.png" : imageUrl} />
        </div>
    )
}
function Title({restaurantTitle}){

    return(
        <div className="restaurant-title">
            {restaurantTitle}
        </div>
    )
}
function Subtitle({status, distanceAway, foodType, reviewInfo}){
    return(
        <div className="restaurant-subtitle">
            <span style={{color : '#65B02A' , fontWeight: '900'}}>
            {status}
            </span> {distanceAway}, {foodType}, {reviewInfo}
        </div>
    )
}

export default RestaurantCard;